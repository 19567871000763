'use client'; 

import Pagination from '@/components/objects/Pagination/Pagination';
import useFetchContens from '@/hooks/useFetchContens.hooks';
import { EndpointType } from '@/types/Endpoints.types';
import { MicroCMSAuthType, MircroCMSPostArchiveRequestQueryType } from '@/types/MicroCMSConfig.types';
import getOffset from '@/utils/getOffset';
import parse from 'html-react-parser';
import { useSearchParams } from 'next/navigation';
import { Suspense } from 'react';
import styles from "./VoiceArchiveBox.module.scss";

export type VoiceAchiveBoxType = {
  microCMSAuth: MicroCMSAuthType;
  microCMSQuery: MircroCMSPostArchiveRequestQueryType;
  endpointInfo: EndpointType;
}

const VoiceAchiveBox = (props: VoiceAchiveBoxType) => {
  const {microCMSAuth, microCMSQuery, endpointInfo} = props;
  
  const searchParams = useSearchParams();
  const currentPageNum = searchParams.get("page") || "1";

  const offset = getOffset(Number(currentPageNum), microCMSQuery.postsPerPage);
  
  const {isLoading, response, hasError, errorMessage, endpointId} = useFetchContens(microCMSAuth, microCMSQuery, offset);

  if (isLoading) {
    return (
      <></>
    );
  };

  if (hasError) {
    return (
      <p style={{textAlign: "center"}}>{parse(errorMessage)}</p>
    );
  }

  return (
    <>
      <Suspense>
        {response.contents.length != 0 ? (
          <>
          <div className={styles['shop-voices']}>
          {response.contents.map((item:any, key:number) => (
            <article key={key} className={styles['shop-voices-item']}>
              <a href={`${endpointInfo.archivePath}post/?id=${item.id}`}>
                {item.eyecatch ? (
                  <div className={styles['shop-voices-item__eyecatch__wrapper']}>
                    <img className={styles['shop-voices-item__eyecatch']} src={`${item.eyecatch.url}?fm=webp`} alt={item.title} />
                  </div>
                ) : (
                  <div className={styles['shop-voices-item__eyecatch__wrapper']}>
                    <img className={styles['shop-voices-item__eyecatch']} src={'/images/noimg_voice.png'} alt={item.title} />
                  </div>
                )}
                <div className={styles['shop-voices-item__properties']}>{item.address}　{item.old}</div>
                <div className={styles['shop-voices-item__title']}>{item.title}</div>
              </a>
            </article>
          ))}
        </div>
        <div className={styles['archive-pagination']}>
          <Pagination
            originalActive={offset}
            totalCount={response.totalCount}
            contentsName={endpointInfo.archivePath}
            postsPerPage={endpointInfo.postsPerPage}
            pageNumber={currentPageNum}
          />
        </div>
        </>
        ): (
          <>
          <p>ただいま準備中です</p>
        </>
        )
      }
    </Suspense>
  </>
  )
}

export default VoiceAchiveBox;