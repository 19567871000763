import { useEffect, useState } from "react";
import { ErrorMessages } from "../../app/_settings/_configs/ErrorMessages";
import { MicroCMSAuthType, MircroCMSPostArchiveRequestQueryType } from "../types/MicroCMSConfig.types";
import { getMicroCMSConnection } from "../utils/getMicroCMSConnection";

const useFetchContens = (
  microCMSAuth: MicroCMSAuthType,
  microCMSQuery: MircroCMSPostArchiveRequestQueryType,
  offset: number = 0,
  categoryId?: string,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<any>();
  const [hasError, setHasError] = useState(false);
  const errorMessage = ErrorMessages.err003;

  const endpointId = microCMSQuery.endpointId;
  const postsPerPage = microCMSQuery.postsPerPage;

  // const [nextOffset, setNextOffset] = useState<number>(0);
  // const [isQueryMax, setIsQueryMax] = useState(false);

  useEffect(() => {
    const client = getMicroCMSConnection(microCMSAuth.serviceDomain, microCMSAuth.apiKey);

    const getContentsList = async() => {
      let apiResponse: any;
      try {
        if (categoryId) {
          apiResponse = await client.get({
            endpoint: endpointId,
            queries: {
              limit: microCMSQuery.postsPerPage,
              offset: offset,
              orders: '-publishedAt',
              filters: `categories[contains]${categoryId}`,
            }
          });

        } else {
          apiResponse = await client.get({
            endpoint: endpointId,
            queries: {
              limit: microCMSQuery.postsPerPage,
              offset: offset,
              orders: '-publishedAt',
            }
          });
        }
        const currentNextOffset = offset + postsPerPage;
        
        setResponse(apiResponse)
      } catch (e) {
        console.error(e);
        setHasError(true);
      } finally {
        setIsLoading(false)
      }
    };
    getContentsList();
  },[])
  return {isLoading, response, hasError, errorMessage, endpointId};
}

export default useFetchContens;