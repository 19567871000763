'use client';

import LayoutSection from "@/components/layouts/LayoutSection/LayoutSection";
import useFetchContentsForShopPage from "@/hooks/useFetchContentsForShopPage.hooks";
import parse from "html-react-parser";
import { Suspense } from "react";
import { Endpoints } from "../../_configs/Endpoints";
import { MicroCMSGetAuthes } from "../../_configs/MicroCMSConfig";
import { MicroCMSQueryForShopPageType } from "../QueryForShopPage.types";
import VoiceAchiveBox from "../VoiceArchiveBox/VoiceArchiveBox";

const BoxVoiceForShop = (props: MicroCMSQueryForShopPageType) => {
  const {microCMSAuth, shopID, endpointName, limit} = props;
  const {isLoading, response, hasError, errorMessage} = useFetchContentsForShopPage(microCMSAuth, shopID, endpointName, limit);

  if (isLoading) {
    return (
      <LayoutSection>
        <></>
      </LayoutSection>
    );
  };

  if (hasError) {
    return (
      <LayoutSection>
        <p style={{textAlign: "center"}}>{parse(errorMessage)}</p>
      </LayoutSection>
    );
  }

  return (
    <Suspense>
      {response ? (
        <VoiceAchiveBox
        microCMSAuth={MicroCMSGetAuthes[shopID]}
        microCMSQuery={
          {
            endpointId: endpointName,
            postsPerPage: Endpoints[shopID][endpointName].postsPerPage
          }
        }
        endpointInfo={Endpoints[shopID][endpointName]}
      />
      ) : null} 
      
    </Suspense>
  )
}

export default BoxVoiceForShop;