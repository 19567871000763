import { MicroCMSAuthType, MicroCMSGetAuthes } from "./MicroCMSConfig";
import { Meta } from "./SiteInfo";

export type EndpointType = {
  /** microCMSのエンドポイントID */
  id: string;

  /** 
   * エンドポイント表示名
   * サイト上のメニューやタイトルの表示用
   */
  name: string;

  /** カテゴリー */
  // categories?: Categories_type;

  /** 一覧ページごとの表示件数上限 */
  postsPerPage: number;

  /** ラベル */
  label: string;

  /** アーカイブページのパス */
  archivePath: string;
  
  /** アーカイブページのdescription */
  description?: string;

  /** カテゴリー */
  categories: {};
};

export type EndpointKindType = Record<string, EndpointType>;
export type EndpointShopType = Record<string, EndpointKindType>;

export const Endpoints:EndpointShopType = {
  "sasaguri": {
    "news": {
      id: "news",
      name: "篠栗院からのお知らせ",
      categories: {},
      postsPerPage: 10,
      label: '篠栗',
      archivePath: '/shop/sasaguri/news/',
      description: Meta.siteName + " | 篠栗院からのお知らせ"
    },
    "blog": {
      id: "blog",
      name: "篠栗院ブログ",
      categories: {},
      postsPerPage: 12,
      label: '篠栗',
      archivePath: '/shop/sasaguri/blog/',
      description: Meta.siteName + " | 篠栗院のブログ"
    },
    "voice": {
      id: "voice",
      name: "篠栗院の患者様の声",
      categories: {},
      postsPerPage: 12,
      label: '篠栗',
      archivePath: '/shop/sasaguri/voice/',
      description: Meta.siteName + " | 篠栗院のお患者の声"
    }
  },
  "noogata": {
    "news": {
      id: "news",
      name: "直方院からのお知らせ",
      categories: {},
      postsPerPage: 10,
      label: '直方',
      archivePath: '/shop/noogata/news/',
      description: Meta.siteName + " | 直方院からのお知らせ"
    },
    "blog": {
      id: "blog",
      name: "直方院ブログ",
      categories: {},
      postsPerPage: 12,
      label: '直方',
      archivePath: '/shop/noogata/blog/',
      description: Meta.siteName + " | 直方院のブログ"
    },
    "voice": {
      id: "voice",
      name: "直方院の患者様の声",
      categories: {},
      postsPerPage: 12,
      label: '直方',
      archivePath: '/shop/noogata/voice/',
      description: Meta.siteName + " | 直方院の患者様の声"
    }
  },
  "hofu": {
    "news": {
      id: "news",
      name: "防府院からのお知らせ",
      categories: {},
      postsPerPage: 10,
      label: '防府',
      archivePath: '/shop/hofu/news/',
      description: Meta.siteName + " | 防府院からのお知らせ"
    },
    "blog": {
      id: "blog",
      name: "防府院ブログ",
      categories: {},
      postsPerPage: 12,
      label: '防府',
      archivePath: '/shop/hofu/blog/',
      description: Meta.siteName + " | 防府院のブログ"
    },
    "voice": {
      id: "voice",
      name: "防府院の患者様の声",
      categories: {},
      postsPerPage: 12,
      label: '防府',
      archivePath: '/shop/hofu/voice/',
      description: Meta.siteName + " | 防府院の患者様の声"
    }
  },
  "shimonoseki": {
    "news": {
      id: "news",
      name: "下関院からのお知らせ",
      categories: {},
      postsPerPage: 10,
      label: '下関',
      archivePath: '/shop/shimonoseki/news/',
      description: Meta.siteName + " | 下関院からのお知らせ"
    },
    "blog": {
      id: "blog",
      name: "下関院ブログ",
      categories: {},
      postsPerPage: 12,
      label: '下関',
      archivePath: '/shop/shimonoseki/blog/',
      description: Meta.siteName + " | 下関院のブログ"
    },
    "voice": {
      id: "voice",
      name: "下関院の患者様の声",
      categories: {},
      postsPerPage: 12,
      label: '下関',
      archivePath: '/shop/shimonoseki/voice/',
      description: Meta.siteName + " | 下関院の患者様の声"
    }
  },
  "nobeoka": {
    "news": {
      id: "news",
      name: "延岡院からのお知らせ",
      categories: {},
      postsPerPage: 10,
      label: '延岡',
      archivePath: '/shop/nobeoka/news/',
      description: Meta.siteName + " | 延岡院からのお知らせ"
    },
    "blog": {
      id: "blog",
      name: "延岡院ブログ",
      categories: {},
      postsPerPage: 12,
      label: '延岡',
      archivePath: '/shop/nobeoka/blog/',
      description: Meta.siteName + " | 延岡院のブログ"
    },
    "voice": {
      id: "voice",
      name: "延岡院の患者様の声",
      categories: {},
      postsPerPage: 12,
      label: '延岡',
      archivePath: '/shop/nobeoka/voice/',
      description: Meta.siteName + " | 延岡院の患者様の声"
    }
  }
}

// 各オブジェクトの構造を定義
export type PostQueryType = {
  microCMSAuth: MicroCMSAuthType; // MicroCMSGetAuthesの返り値型に合わせる
  endpoint: EndpointType;   // Endpointsのプロパティ型に合わせる
};

// `endpoints` プロパティと `postsPerPage` を含む型を定義
export type PostQueriesType = {
  endpoints: Record<string, PostQueryType>;
  postsPerPage: number; // ページごとの投稿数
};

export const TopLatestNewsEndpoints: PostQueriesType = {
  endpoints: {
    "sasaguri": {
    microCMSAuth: MicroCMSGetAuthes["sasaguri"],
    endpoint: Endpoints["sasaguri"].news
    },
    "noogata": {
    microCMSAuth: MicroCMSGetAuthes["noogata"],
    endpoint: Endpoints["noogata"].news
    },
    "hofu": {
      microCMSAuth: MicroCMSGetAuthes["hofu"],
      endpoint: Endpoints["hofu"].news
    },
    "shimonoseki": {
      microCMSAuth: MicroCMSGetAuthes["shimonoseki"],
      endpoint: Endpoints["shimonoseki"].news
    },
    "nobeoka": {
      microCMSAuth: MicroCMSGetAuthes["nobeoka"],
      endpoint: Endpoints["nobeoka"].news
    }  
  },
  postsPerPage: 3
}

export const LatestBlogsEndpoints: PostQueriesType = {
  endpoints: {
    "sasaguri": {
      microCMSAuth: MicroCMSGetAuthes["sasaguri"],
      endpoint: Endpoints["sasaguri"].blog
    },
    "noogata": {
      microCMSAuth: MicroCMSGetAuthes["noogata"],
      endpoint: Endpoints["noogata"].blog
    },
    "hofu": {
      microCMSAuth: MicroCMSGetAuthes["hofu"],
      endpoint: Endpoints["hofu"].blog
    },
    "shimonoseki": {
      microCMSAuth: MicroCMSGetAuthes["shimonoseki"],
      endpoint: Endpoints["shimonoseki"].blog
    },
    "nobeoka": {
      microCMSAuth: MicroCMSGetAuthes["nobeoka"],
      endpoint: Endpoints["nobeoka"].blog
    }  
  },
  postsPerPage: 3
}

export const LatestVoiceEndpoints: PostQueriesType = {
  endpoints: {
    "sasaguri": {
      microCMSAuth: MicroCMSGetAuthes["sasaguri"],
      endpoint: Endpoints["sasaguri"].voice
    },
    "noogata": {
      microCMSAuth: MicroCMSGetAuthes["noogata"],
      endpoint: Endpoints["noogata"].voice
    },
    "hofu": {
      microCMSAuth: MicroCMSGetAuthes["hofu"],
      endpoint: Endpoints["hofu"].voice
    },
    "shimonoseki": {
      microCMSAuth: MicroCMSGetAuthes["shimonoseki"],
      endpoint: Endpoints["shimonoseki"].voice
    },
    "nobeoka": {
      microCMSAuth: MicroCMSGetAuthes["nobeoka"],
      endpoint: Endpoints["nobeoka"].voice
    },
  },
  postsPerPage: 3
}

// export const TopLatestNewsEndpoints = {
//   "news_sasaguri": Endpoints.sasaguri.news,
//   // "news_noogata": Endpoints.noogata.news,
//   "news_hofu": Endpoints.hofu.news,
//   // "news_shimonoseki": Endpoints.shimonoseki.news,
//   // "news_nobeoka": Endpoints.nobeoka.news,
// }

export const NewsEndpointsForMenu = [
  Endpoints.sasaguri.news,
  Endpoints.noogata.news,
  Endpoints.hofu.news,
  Endpoints.shimonoseki.news,
  Endpoints.nobeoka.news,
]
export const BlogEndpointsForMenu = [
  Endpoints.sasaguri.blog,
  Endpoints.noogata.blog,
  Endpoints.hofu.blog,
  Endpoints.shimonoseki.blog,
  Endpoints.nobeoka.blog,
]
export const VoiceEndpointsForMenu = [
  Endpoints.sasaguri.voice,
  Endpoints.noogata.voice,
  Endpoints.hofu.voice,
  Endpoints.shimonoseki.voice,
  Endpoints.nobeoka.voice,
]