'use client'; 

import Pagination from '@/components/objects/Pagination/Pagination';
import useFetchContens from '@/hooks/useFetchContens.hooks';
import { EndpointType } from '@/types/Endpoints.types';
import { MicroCMSAuthType, MircroCMSPostArchiveRequestQueryType } from '@/types/MicroCMSConfig.types';
import getFormatedDate from '@/utils/getFormatedDate';
import getOffset from '@/utils/getOffset';
import getStripHtmlTagsText from '@/utils/getStripHtmlTagsText';
import parse from 'html-react-parser';
import { useSearchParams } from 'next/navigation';
import { Suspense } from 'react';
import styles from "./BlogAchiveBox.module.scss";

export type BlogAchiveBoxType = {
  microCMSAuth: MicroCMSAuthType;
  microCMSQuery: MircroCMSPostArchiveRequestQueryType;
  endpointInfo: EndpointType;
  shopID: string;
}

const BlogArchiveBox = (props: BlogAchiveBoxType) => {
  const {microCMSAuth, microCMSQuery, endpointInfo, shopID} = props;

  const searchParams = useSearchParams();
  const currentPageNum = searchParams.get("page") || "1";
  const searchCategory = searchParams.get("cat") || "";

  const offset = getOffset(Number(currentPageNum), microCMSQuery.postsPerPage);
  
  const {isLoading, response, hasError, errorMessage, endpointId} = useFetchContens(microCMSAuth, microCMSQuery, offset, searchCategory);

  if (isLoading) {
    return (
      <></>
    );
  };

  if (hasError) {
    return (
      <p style={{textAlign: "center"}}>{parse(errorMessage)}</p>
    );
  }

  return (
    <Suspense>
      {response.contents.length != 0 ? (
        <>
          <div className={styles['shop-blogs']}>
            {response.contents.map((item:any, key:number) => (
              <article key={key} className={styles['shop-blogs-item']}>
                <a key={key} href={`${endpointInfo.archivePath}post/?id=${item.id}`}>
                  <div className={styles['shop-blogs-item__eyecatch-wrapper']}>
                    {item.eyecatch ? (
                      <img className={styles['shop-blogs-item__eyecatch']} src={`${item.eyecatch.url}?fm=webp&w=750`} alt={item.title} />
                    ) : (
                      <img className={styles['shop-blogs-item__eyecatch']} src={'/images/noimg.png'} alt={item.title} />
                    )}
                  </div>
                
                  <div className={styles['shop-blogs-item__info-wrapper']}>
                    <div className={styles['shop-blogs-item__date']}>
                      {getFormatedDate(item.publishedAt)}
                      {/* <span className={styles['shop-blogs-item__label']}>{endpointInfo.label}</span> */}
                      {item.categories && (
                        <ul className={styles['shop-blogs-item__categories']}>
                          {item.categories.map((category: string, key: number) => (
                            <span className={styles['shop-blogs-item__category']} key={key}>
                              {category}
                            </span>
                          ))}
                        </ul>
                      )}
                    </div>
                    <div className={styles['shop-blogs-item__title']}>{item.title}</div>
                    <div className={styles['shop-blogs-item__short-content']}>{getStripHtmlTagsText(item.content)}</div>
                  </div>
                </a>
            </article>
            ))}
          </div>
          <div className={styles['archive-pagination']}>
            <Pagination
              originalActive={offset}
              totalCount={response.totalCount}
              contentsName={endpointInfo.archivePath}
              postsPerPage={endpointInfo.postsPerPage}
              pageNumber={currentPageNum}
            />
        </div>
      </>
      ) : 
        <>
          <p>ただいま準備中です</p>
        </>
      }
    </Suspense>
  )
}

export default BlogArchiveBox;