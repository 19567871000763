'use client';

import LayoutSection from "@/components/layouts/LayoutSection/LayoutSection";
import useFetchContentsForShopPage from "@/hooks/useFetchContentsForShopPage.hooks";
import { Suspense } from "react";
import { Endpoints } from "../../../_settings/_configs/Endpoints";
import { MicroCMSGetAuthes } from "../../../_settings/_configs/MicroCMSConfig";
import BlogAchiveBox from "../../../_settings/_templates/BlogArchiveBox/BlogArchiveBox";
import { MicroCMSQueryForShopPageType } from "../../../_settings/_templates/QueryForShopPage.types";

const BoxBlogForShop = (props: MicroCMSQueryForShopPageType) => {
  const {microCMSAuth, shopID, endpointName, limit} = props;

  const {isLoading, response, hasError, errorMessage } = useFetchContentsForShopPage(microCMSAuth, shopID, endpointName, limit);

  if (isLoading) {
    return (
      <LayoutSection>
        <></>
      </LayoutSection>
    );
  };

  if (hasError) {
    return (
      <LayoutSection>
        <p style={{textAlign: "center"}}>{errorMessage}</p>
      </LayoutSection>
    );
  }

  return (
    <Suspense>
      {response ? (
        <BlogAchiveBox
          microCMSAuth={MicroCMSGetAuthes[shopID]}
          microCMSQuery={
            {
              endpointId: endpointName,
              postsPerPage: Endpoints[shopID][endpointName].postsPerPage
            }
          }
          endpointInfo={Endpoints[shopID][endpointName]}
          shopID={shopID}
        />
      ) : null}
    </Suspense>
  )
}

export default BoxBlogForShop;