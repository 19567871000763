"use client";

import ResponsiveImage from "@/components/elements/images/ResponsiveImage/ResponsiveImage";
import LinkButton from "@/components/elements/links/LinkButton/LinkButton";
import DynamicTitle from "@/components/elements/texts/DynamicTitle/DynamicTitle";
import useFetchShopDetail from "@/hooks/useFetchShopDetail.hooks";
import parse from "html-react-parser";
import { Suspense } from "react";
import { MicroCMSGetAuthes } from "../../../_settings/_configs/MicroCMSConfig";
import { snsIcons } from "../../../_settings/_configs/SiteInfo";
import ScheduleTable from "../../../_settings/_templates/ScheduleTable/ScheduleTable";
import ShopSectionWrapper from "../ShopSectionWrapper/ShopSectionWrapper";
import styles from "./SectionShopDetailedInformation.module.scss";
import { SectionShopDetailedInformationType } from "./SectionShopDetailedInformation.types";

// SNSのキーの型を定義
// type SNSKey = "facebook" | "instagram" | "line";

const SectionShopDetailedInformation = (props: SectionShopDetailedInformationType) => {
  const { shopId, endpointId, microCMSAuth, shopInfo } = props;

  const {isLoading, response, hasError, errorMessage} = useFetchShopDetail(shopId, endpointId, MicroCMSGetAuthes["shop"]);

  return (
    <Suspense>
      <ShopSectionWrapper>

        <div className={styles['shop-detail']}>
          <div className={styles['shop-detail__welcome']}>
            <DynamicTitle
              tag={"h2"}
              text={"店舗情報"}
              textSub={"Info"}
            />
            {/* <p>{parse(shopDetailedItem.text)}</p> */}
          </div>

          <div className={styles['shop-detail__basic']}>
            <div className={styles['shop-detail__cover']}>
              {/* <DynamicTitle 
                tag={"h3"}
                text={shopInfo.shopInfoItem[shopId].shopName}
              /> */}
              <div className={styles['shop-detail__basic-cover']}>
                <ResponsiveImage
                  srcPC={shopInfo.shopInfoItem[shopId].coverPicture.srcPC}
                  srcSP={shopInfo.shopInfoItem[shopId].coverPicture.srcSP}
                  altText={shopInfo.shopInfoItem[shopId].coverPicture.altText}
                />
              </div>
            </div>
            <div className={styles['shop-detail__name-area']}>
              <h3 className={styles['shop-detail__name-area-title']}>
                {parse(shopInfo.shopInfoItem[shopId].shopName)}
              </h3>
              {response && 
                <ul className={styles['shop-detail__name-area-sns']}>
                  <li>
                    <a href={response.urls.facebook && response.urls.facebook} target="_blank" rel="noopener noreferer">
                      <img src={snsIcons.facebook} alt={shopInfo.shopInfoItem[shopId].shopName} />
                    </a>
                  </li>
                  <li>
                    <a href={response.urls.instagram && response.urls.instagram} target="_blank" rel="noopener noreferer">
                      <img src={snsIcons.instagram} alt={shopInfo.shopInfoItem[shopId].shopName} />
                    </a>
                  </li>
                  <li>
                    <a href={response.urls.line && response.urls.line} target="_blank" rel="noopener noreferer">
                      <img src={snsIcons.line} alt={shopInfo.shopInfoItem[shopId].shopName} />
                    </a>
                  </li>
                </ul>
              }
            </div>

            {response && 
              <ul className={styles['shop-detail__related']}>
                {response.features.map((item: string, key: number) => (
                  <li key={key} data-icon={item}>
                    {item}
                  </li>
                ))}
              </ul>
            }

            <div className={styles['shop-detail__explain']}>
              <div className={styles['shop-detail__explain-image-wrap']}>
                <ResponsiveImage
                  srcPC={shopInfo.shopInfoItem[shopId].coverPicture.srcPC}
                  srcSP={shopInfo.shopInfoItem[shopId].coverPicture.srcSP}
                  altText={shopInfo.shopInfoItem[shopId].coverPicture.altText}
                />
              </div>
              <div className={styles['shop-detail__explain-text']}>
                <dl className={styles["shop-detail__access"]}>
                  <dt>住所</dt>
                  <dd>
                      <div className={styles['shop-detail__address']}>
                        {response && response.address}
                      </div>
                      <a href={response && response.gmap.url} className={styles['shop-detail__map-pin']} target="_blank" rel="noopener noreferer">
                        google mapはこちら
                      </a>
                  </dd>
                  <dt>電話番号</dt>
                  <dd>{response && response.tel}</dd>
                  <dt>診療時間</dt>
                  <dd>
                    午前 {response && response.shinryo?.openAM} {response?.shinryo?.openAMBikou && (<>({response.shinryo.openAMBikou})</>)}<br />
                    午後 {response && response.shinryo?.openPM} {response?.shinryo?.openPMBikou && (<>({response.shinryo.openPMBikou})</>)}
                  </dd>
                  {response?.closed && 
                    (
                      <>
                        <dt>定休日</dt>
                        <dd>{response.closed}</dd>
                      </>
                    )
                  }
                  <dt>決済方法</dt>
                  <dd>{response && response.payments}</dd>
                </dl>
              </div>
              </div>
            </div>
            

            <div className={styles['shop-detail__schedule']}>
              <ScheduleTable
                shinryo={response && response.shinryo}
                table={response && response.table}
              />
            </div>

            <div className={styles['shop-detail__googlemap']}>
              {response && parse(response.gmap.iframe)}
            </div>

            <div className={styles['shop-detail__access-guide']}>
              <DynamicTitle 
                tag={"h3"}
                text={"アクセスガイド"}
              />
              <div className={styles['shop-detail__access-way']}>
                <div className={styles['shop-detail__access-label']}>車でお越しの場合</div>
                <div>{response && parse(response.access.byCar)}</div>
              </div>

              <div className={styles['shop-detail__access-way']}>
                <div className={styles['shop-detail__access-label']}>電車でお越しの場合</div>
                <div>{response && parse(response.access.byTrain)}</div>
              </div>
              
              <div className={styles['shop-detail__ekiten-link']}>
                <LinkButton
                  linkText="ネット予約はこちら"
                  linkTo={response && response.urls.ekiten}
                  isExternal={true}
                />
              </div>
            </div>
          </div>
      </ShopSectionWrapper>
    </Suspense>
  )
}

export default SectionShopDetailedInformation;
