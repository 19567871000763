import { getMicroCMSConnection } from "@/utils/getMicroCMSConnection";
import { useEffect, useState } from "react";
import { Endpoints } from "../../app/_settings/_configs/Endpoints";
import { MicroCMSAuthType } from "../types/MicroCMSConfig.types";

const useFetchContentsForShopPage = (
  microCMSAuth: MicroCMSAuthType,
  shopID: string,
  endpointID: string,
  limit: number,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<any>();
  const [hasError, setHasError] = useState(false);
  const errorMessage = "投稿が取得できません。";

  const endpointId = endpointID;
  const postsPerPage = limit;
  const label = Endpoints[shopID][endpointID].label;

  useEffect(() => {
    const client = getMicroCMSConnection(microCMSAuth.serviceDomain, microCMSAuth.apiKey);

    const getContentsList = async() => {
      try {
        const apiResponse = await client.get({
          endpoint: endpointId,
          queries: {
            limit: postsPerPage,
            orders: '-publishedAt',
          }
        });
        
        setResponse(apiResponse)
      } catch (e) {
        console.error(e);
        setHasError(true);
      } finally {
        setIsLoading(false)
      }
    };
    getContentsList();
  },[microCMSAuth, endpointId, postsPerPage])
  return {isLoading, response, hasError, errorMessage, endpointId, label};
}

export default useFetchContentsForShopPage;