'use client';

import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from "react";
import styles from './Pagination.module.scss';
import PaginationType from "./PaginationType";

const Pagination = (props: PaginationType) => { 
  const { originalActive, totalCount, contentsName, postsPerPage, pageNumber } = props;

  // console.log(pageNumber); // デバッグ用：現在のページ番号をログ出力

  const searchParams = useSearchParams(); // URLの検索パラメータを取得するためのフック
  const searchCategory = searchParams.get("cat") || ""; // 'cat'パラメータを取得。なければ空文字

  const url = contentsName; // コンテンツ名をURLとして利用

  // 投稿数とページごとの表示件数からページ数を計算
  const pageNum = Math.ceil(totalCount / postsPerPage);
  const pageList = Array.from({ length: pageNum }, (_, index) => index + 1); // ページ数分の配列を作成
  const listLength = pageList.length || 0; // ページ数の長さを取得。なければ0を返す

  // console.log("pageList: " + pageList); // デバッグ用：ページリストをログ出力

  // 表示するページ数の上限を設定
  const displayedListLength = 5;

  // 「...」を使って省略を示す記号を定義
  const morePageMark = "…"

  // console.log("pageNumber: " + pageNumber); // デバッグ用：現在のページ番号をログ出力
  const [activeItem, setActiveItem] = useState(originalActive); // 現在のアクティブなページアイテムを管理するステート
  const [displayedList, setDisplayedList] = useState<Array<number | string>>(pageList); // 表示するページリストのステートを設定

  useEffect(() => {
    updateDisplayedList(); // 初回レンダリング時に表示リストを更新
  }, []);

  const updateDisplayedList = () => { // 表示リストを更新する関数
    const firstPage = 1; // 最初のページを定義
    const lastPage = pageList[pageList.length - 1]; // 最後のページを取得
    const pageNum = Number(pageNumber) || 1; // 現在のページ番号を取得し、数値化。なければ1

    const newDisplayedList = [firstPage]; // 新しい表示リストの最初に1ページ目を追加
    
    // 中央のページ範囲の計算
    const halfLength = Math.floor((displayedListLength - 2) / 2); // 表示リストの中央範囲を計算
    let startPage = Math.max(firstPage + 1, pageNum - halfLength); // 表示リストの開始ページを決定
    let endPage = Math.min(lastPage - 1, pageNum + halfLength); // 表示リストの終了ページを決定
  
    // 表示するリストのページ数がdisplayedListLength未満の場合に範囲を調整
    if (endPage - startPage + 1 < displayedListLength - 2) {
      if (pageNum <= halfLength + 1) {
        endPage = Math.min(lastPage - 1, startPage + displayedListLength - 3);
      } else if (pageNum + halfLength >= lastPage) {
        startPage = Math.max(firstPage + 1, endPage - (displayedListLength - 3));
      }
    }
  
    // 中央のページ番号を追加
    for (let i = startPage; i <= endPage; i++) {
      newDisplayedList.push(i);
    }
  
    // 最後のページを追加
    if (lastPage !== firstPage) {
      newDisplayedList.push(lastPage);
    }
  
    // 「...」を必要な箇所に挿入
    const resultList: (number | string)[] = [];
    newDisplayedList.forEach((page, index) => {
      if (index > 0 && page - newDisplayedList[index - 1] > 1) {
        resultList.push(morePageMark); // ページ番号が離れている場合に「...」を追加
      }
      resultList.push(page); // ページ番号を追加
    });

    setDisplayedList(resultList); // 更新したリストをセット
  };
  
  const movePage = (increment: number) => () => { // ページを移動する関数
    updateDisplayedList(); // 表示リストを更新
  };

  return (
    <>
      {pageList.length <= 1 ? ( // ページが1つだけの場合、ページネーションを表示しない
        null
      ): (
        <>
          <ul className={styles["contentsList"]}> {/* ページネーションのリスト */}
            {displayedList.map((data, index) => ( // 表示リストをループしてリスト項目を生成
              data == pageNumber ? ( // 現在のページの場合
                <li key={index} className={styles['page-active']}>
                  <span>{data}</span> {/* アクティブなページを強調表示 */}
                </li>
              ) : data == morePageMark ? ( // 省略記号の場合
                <li key={index} className={styles['page-dots']}>
                  <span>{data}</span> {/* 省略記号を表示 */}
                </li>
              ) : (
                <li key={index}>
                  <a href={`${url}?page=${data}${searchCategory ? `&cat=${searchCategory}` : ''}`}>
                    <span>{data}</span> {/* その他のページリンク */}
                  </a>
                </li>
              )
            ))}
          </ul>

          <div className={styles.pagination__count}>
            {pageNumber}/{pageNum}ページ {/* 現在のページと全ページ数を表示 */}
          </div>
        </>
      )}
    </>
  );

}

export default Pagination; // コンポーネントをエクスポート
