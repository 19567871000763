// GET API以外は絶対に.env以外に置かない
// .envを使える(node環境でSSRするとき)ようになったら.envに移設してこのファイルは消すこと
export type MicroCMSAuthType = {
  /**
   * microCMSサービスドメイン
   * 通常はenvファイルから取得
   */
  serviceDomain: string;

  /**
   * microCMS APIキー
   * 通常はenvファイルから取得
   */
  apiKey: string;
}

/** 
 * 各院ごとのAPIを定義するため、文字列（院名など）をキーとして、MicroCMSAuthType を使用しているオブジェクト型を定義 
 * 以下と同意
 * export type MicroCMSGetAuthesType = {
 *   [key: string]: MicroCMSAuthType;
 * }
*/
export type MicroCMSGetAuthesType = Record<string, MicroCMSAuthType>;

// ビジネスプランのAPIとサービスドメイン
// [todo]リベースするときは削除でOK
// export const MicroCMSGetAuth: MicroCMSAuthType = {
//   // 本番
//   serviceDomain: "zizaina",
//   apiKey: "gVMvmstWqRWbYB6SaYmZ44Y9xyM9qxiITyoE"
// }

// microCMSサービスリスト
// export const microCMSServices = ["sasaguri",];


/**
 *  【無料プラン】APIとサービスドメイン
 * */ 
export const MicroCMSGetAuthes: MicroCMSGetAuthesType = {
  "sasaguri" : {
    serviceDomain: "zizainasasaguri",
    apiKey: "i99hT31hqcKKWoC6jLyuilWS5G6sMBNKw6Lu"
  },
  "noogata" : {
    serviceDomain: "zizainanoogata",
    apiKey: "GRo6hIPpsLpwt5rJ0gpNPYdd4VlaZriVg4IJ"
  },
  "hofu" : {
    serviceDomain: "zizainahofu",
    apiKey: "0sYRTzReU3OvMe3uGVSaGzvbR81JGv97Oo7x"
  },
  "shimonoseki" : {
    serviceDomain: "zizainashimonoseki",
    apiKey: "ktXE0xQE8JWRDxwoekaZbjYnPUWToSfK9jp4"
  },
  "nobeoka" : {
    serviceDomain: "zizainanobeoka",
    apiKey: "0MlOybWYPCfHHOTcvFymcUFNXg9h8TGMofTK"
  },
  // 店舗情報
  "shop" : {
    serviceDomain: "zizainashop",
    apiKey: "MtPP4P6BYJBJvVrOOxHRHjq8edSJeqnhMXsO"
  },
}
